<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Pagination from "@/components/widgets/pagination";

import Tablesaw from 'tablesaw'
import {reloadTablesaw} from "@/helpers/datatable";

import store from "@/state/store";
import moment from "moment";
import {apiMessagesMethods} from "@/state/helpers";
import Loader from "@/components/widgets/loader";
import Modal from "@/components/modals/sms-modal";

// 검색 기본값
const BASE_SEARCH = {
  dateField: 'created_at',
  field: 'fixedcrypt:phone',
  keyword: '',
  startDate : '',
  endDate : '',
  period: 7,
  page: 1,
}

export default {
  components: { Layout, PageHeader, Pagination, Loader, Modal },
  data() {
    return {
      menuId: 400200,
      menuCode: "sms",
      currentPage: 1,
      totalCount: 0,
      listData: [],
      isPageLoading: false,
      datepicker: {
        startDate : null,
        endDate : null,
      },
      search: BASE_SEARCH,
      // 검색키워드 항목
      fields: [
        { value: 'fixedcrypt:phone', text: '전화번호' },
        { value: 'sms.content', text: '메시지' },
      ],
      periods: [
        { value: 7, text: '1주일' },
        { value: 1, text: '1개월' },
        { value: 3, text: '3개월' },
        //{ value: 6, text: '6개월' },
        //{ value: 0, text: '전체' }
      ],
      showModal: false,
      modalData: {},
    };
  },
  created() {
    this.$changeMenu(this.menuId, this.menuCode)
  },
  mounted () {
    Tablesaw._init();
  },
  beforeRouteUpdate(to, from, next) {
    if(to.fullPath !== from.fullPath) {
      this.initSearch(to.query)
      next();
    }
  },
  methods: {
    ...apiMessagesMethods,

    onRoutechange() {
      this.initSearch(this.$route.query)
      this.loadData()
    },

    // 데이터 추출
    loadData() {
      this.searchDate()

      if (!moment(this.search.startDate, 'YYYY-MM-DD', true).isValid()) {
        this.search.startDate = ""
      }

      if (!moment(this.search.endDate, 'YYYY-MM-DD', true).isValid()) {
        this.search.endDate = ""
      }

      let page = this.search.page

      // 검색 조건 결합
      let params = {
        ...this.$route.query,
        ...this.search,
        page: page
      };
      // console.log(params);

      this.isPageLoading = true
      store.dispatch("apiMessages/sms", { params }).then((result) => {
        this.updateTableData(result.data, result.recordsFiltered)
        this.isPageLoading = false
      }, () => {
        this.isPageLoading = false
      })
    },

    // 데이터 가공
    updateTableData(data, total) {
      this.listData = data;
      this.totalCount = total;

      this.$nextTick(function () {
        // Tablesaw가 동적으로 처리되지 않아 별도 처리
        reloadTablesaw(this.$refs.tablesaw)
      })
    },

    // 라우트 변경 ( 브라우져 뒤로가기 구현 )
    changeRouter() {
      // 검색조건에 검색기간 설정
      this.searchDate()

      if (!moment(this.search.startDate, 'YYYY-MM-DD', true).isValid()) {
        this.search.startDate = "all"
      }

      if (!moment(this.search.endDate, 'YYYY-MM-DD', true).isValid()) {
        this.search.endDate = "all"
      }

      let page = this.search.page
      let params = { page: page };

      // 쿼리스트링 결합
      let query = {
        ...this.$route.query,
        ...this.search,
        ...params
      };

      // 히스토리 추가
      this.$router.push({ query })
    },

    // 페이징 처리
    changePage(page) {
      if (this.search.page != page) {
        this.currentPage = this.search.page = page
        this.changeRouter()
      }
    },

    // 검색 초기값 설정
    initSearch(routeQuery) {
      if (routeQuery.startDate != "all" && !moment(routeQuery.startDate, 'YYYY-MM-DD', true).isValid()) {
        this.datepicker.startDate = new Date(moment().add(-7, 'days').format('YYYY-MM-DD'))
      }
      if (routeQuery.endDate != "all" && !moment(routeQuery.endDate, 'YYYY-MM-DD', true).isValid()) {
        this.datepicker.endDate = new Date(moment().format('YYYY-MM-DD'))
      }

      this.search = Object.assign({}, {...BASE_SEARCH, ...routeQuery})
      this.search.page = this.search.page * 1
      this.currentPage = this.search.page

      // 라우트가 변경되면 검색기간 변경
      if (this.search.startDate) {
        this.datepicker.startDate = new Date(this.search.startDate)
      }
      if (this.search.endDate) {
        this.datepicker.endDate = new Date(this.search.endDate)
      }
    },

    // 검색 실행
    searchData() {
      this.currentPage = this.search.page = 1

      // 라우트가 변경되면 loadData 호출
      this.changeRouter()
    },

    // 검색기간 설정
    searchDate() {
      if (this.datepicker.startDate) {
        this.search.startDate = moment(this.datepicker.startDate).format('YYYY-MM-DD')
      } else {
        this.search.startDate = "all"
      }

      if (this.datepicker.endDate) {
        this.search.endDate = moment(this.datepicker.endDate).format('YYYY-MM-DD')
      } else {
        this.search.endDate = "all"
      }
    },

    // 단위별 검색기간 변경
    // 전체:0, 일주일:7, 월단위:1,3,6,9,12, 일단위:12~
    searchPeriod(period) {
      this.search.period = period
      if (period > 0) {
        this.datepicker.endDate = new Date(moment().format('YYYY-MM-DD'))
      } else {
        this.search.endDate = "all"
        this.datepicker.endDate = ""
      }
      if (period == 7 || period > 12) {
        this.datepicker.startDate = new Date(moment().add(period*(-1), 'days').format('YYYY-MM-DD'))
      } else if (period) {
        this.datepicker.startDate = new Date(moment().add(period*(-1), 'months').format('YYYY-MM-DD'))
      } else {
        this.search.startDate = "all"
        this.datepicker.startDate = ""
      }
      this.searchData()
    },

    // 현재 설정된 검색기간 스타일 변경
    variantPeriod(period) {
      if (period === 7 && !this.$route.query.period) {
        return "info"
      } else {
        return (period == this.$route.query.period) ? "info" : "outline-secondary"
      }
    },

    openModal: function(event) {
      this.modalData = event;
    },
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader />
    <Modal v-if="showModal" :modal-data="modalData" @close="showModal = false" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card min-content">
          <div class="card-body">
            <div>
              <b-form class="px-2" @submit.prevent="searchData">
                <div class="form-group row mb-2 pb-2 search-line">
                  <label class="col-form-label col-sm-2 text-nowrap">전송일 검색</label>
                  <div class="col-sm input-group">
                    <div class="col-sm-2 pe-2 mb-1 search-date">
                      <date-picker
                          input-class="form-control"
                          v-model:value="datepicker.startDate"
                          value-type="date"
                      ></date-picker>
                    </div>
                    <div class="col-sm-2 pe-2 mb-1 search-date">
                      <date-picker
                          input-class="form-control"
                          v-model:value="datepicker.endDate"
                          value-type="date"
                      ></date-picker>
                    </div>
                    <div class="col-sm-2 w-auto text-nowrap">
                      <b-button
                          v-for="period in periods"
                          :key="period.value"
                          :variant="variantPeriod(period.value)"
                          @click="searchPeriod(period.value)"
                          class="spacing-10 me-1"
                      >{{ period.text }}</b-button>
                    </div>
                    <div class="col-sm-2 w-auto text-nowrap ms-1 pt-2 text-danger">
                      ※ 3개월 이전 조회 불가
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-3 pb-2 search-line">
                  <label class="col-form-label col-sm-2 text-nowrap">키워드 검색</label>
                  <div class="col-sm input-group text-nowrap">
                    <b-form-select
                        for="field"
                        v-model="search.field"
                        :options="fields"
                        class="form-control"
                        style="width:100px;flex:none"
                    ></b-form-select>
                    <b-form-input
                        for="keyword"
                        v-model="search.keyword"
                        class="form-control"
                    ></b-form-input>
                  </div>
                </div>
                <div class="row justify-content-between pb-3">
                  <div class="col">
                    <b-button type="submit" variant="primary">검색하기</b-button>
                  </div>
                </div>
              </b-form>
              <div class="position-relative" style="min-height: 130px;">
                <table ref="tablesaw" class="tablesaw table align-middle table-nowrap table-hover" data-tablesaw-mode="columntoggle">
                  <thead class="table-light">
                  <tr>
                    <th scope="col" class="wd-60" data-tablesaw-priority="3">순번</th>
                    <th scope="col" class="text-start" data-tablesaw-priority="persist">메시지</th>
                    <th scope="col" class="wd-50" data-tablesaw-priority="persist">전화번호</th>
                    <th scope="col" class="wd-50" data-tablesaw-priority="persist">성공</th>
                    <th scope="col" class="wd-120" data-tablesaw-priority="4">전송일시</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="list in listData" :key="list.id">
                    <td>{{ list.rownum }}</td>
                    <td class="text-start ellipsis ellipsis-wide"><span><a class="dotted-link" href="javascript:void(0);" @click="openModal(list), (showModal = true)">{{ list.content }}</a></span></td>
                    <td>{{ list.phone }}</td>
                    <td>{{ list.success }}</td>
                    <td>{{ list.register_date }}</td>
                  </tr>
                  </tbody>
                </table>
                <div class="position-absolute base-list-message pt-5" v-show="!isPageLoading && listData.length === 0">조회된 내역이 없습니다.</div>
                <Loader :loading="isPageLoading" class="position-absolute base-list-loader mt-5"></Loader>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <Pagination :total="totalCount" v-model:page="currentPage" @changePage="changePage($event)"></Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

